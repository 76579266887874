import { CD, PD } from "../../estates/details/fields/Ansprechpartner";

import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { Carousel } from "react-bootstrap";

const WhatWeWant = () => {
  return (
    <Carousel>
      <Carousel.Item>
        <StaticImage
          className="d-block w-100"
          src={"../../../assets/images/interviews/wir-machen-nachbarn.jpg"}
          alt={PD}
          placeholder="blurred"
          layout="fullWidth"
          quality={100}
        />
        <Carousel.Caption>
          <div className="caption-content">
            <hr className="gold-line" />
            <q>
            Wir machen Nachbarn.
            </q>
            <br />
            <br />
            <br />
            <br />
            <br />
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <StaticImage
          className="d-block w-100"
          src={"../../../assets/images/interviews/warum-wir-philip.jpg"}
          alt={PD}
          placeholder="blurred"
          layout="fullWidth"
          quality={100}
        />
        <Carousel.Caption>
          <div className="caption-content">
            <hr className="gold-line" />
            <q>
              Wir wollten genau die Makler sein, {"\n"}die wir uns als Kunden
              selbst immer {"\n"}gewünscht haben.
            </q>
            <br />
            <b>Philip</b>
            <br />
            <br />
            <br />
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <StaticImage
          className="d-block w-100"
          src={"../../../assets/images/interviews/warum-wir-christiane.jpg"}
          alt={CD}
          placeholder="blurred"
          layout="fullWidth"
          quality={100}
        />
        <Carousel.Caption>
          <div className="caption-content">
            <hr className="gold-line" />
            <q>
              Andere glücklich zu machen mit dem, was wir tun und wie wir es
              tun, das ist für mich das Schönste an unserer Arbeit. Besonders
              deutlich spüre ich das bei der Schlüsselübergabe, wenn ich sage:
              ‚Willkommen zu Hause‘.
            </q>
            <br />
            <b>Christiane</b>
            <br />
            <br />
            <br />
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <StaticImage
          className="d-block w-100"
          src={"../../../assets/images/interviews/warum-wir-nicole.jpg"}
          alt={"Nicole Maas"}
          placeholder="blurred"
          layout="fullWidth"
          quality={100}
        />
        <Carousel.Caption>
          <div className="caption-content">
            <hr className="gold-line" />
            <q>
              Ich arbeite mit Leidenschaft im {"\n"}Backoffice.
              So schaffe ich es, {"\n"}
              die Kunden und das Team {"\n"}glücklich zu machen.
            </q>
            <br />
            <b>Nicole</b>
            <br />
            <br />
            <br />
          </div>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
};

export default WhatWeWant;
