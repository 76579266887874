import { Container } from "react-bootstrap";
import React from "react";

const FullService = () => {
  return (
    <Container>
      <h2 className="gold-header">Full-Service-Angebot</h2>
      <p className="line-height-2 center-left">
        Wer eine Immobilie kaufen, verkaufen oder vermieten will, muss an
        einiges denken – vom Energieausweis über attraktive Bilder bis hin zur
        passgenauen Vermarktung. Wir haben an alles gedacht und bieten Ihnen
        einen umfassenden persönlichen Service rund um Ihr Immobilienanliegen.
      </p>
    </Container>
  );
};

export default FullService;
