import EstateSlider from "./EstateSlider";
import OnOfficeEstate from "../../../types/estate";
import OnOfficeEstatesQuery from "../../../queries/onOfficeEstates";
import React from "react";

const SoldEstate = () => {
  const soldEstates: OnOfficeEstate[] = OnOfficeEstatesQuery().filter(
    (obj) => obj.ind_2038_Feld_ObjTech66 === "ind_Schl_3023" // referenz
  );

  return <EstateSlider estates={soldEstates} header="Referenzen" />;
};

export default SoldEstate;
