import { Carousel, Container } from "react-bootstrap";

import Estate from "../Estate";
import OnOfficeEstate from "../../../types/estate";
import React from "react";
import { v4 } from "uuid";

interface EstateSliderProps {
  estates: OnOfficeEstate[];
  showDetails?: boolean;
  header: string;
}

const EstateSlider = (props: EstateSliderProps) => {
  const cardNumber = 3;
  const showDetails = props.showDetails ? props.showDetails : false;

  return (
    <>
      {props.estates.length > 0 ? (
        <>
          <Container>
            <h2 className="gold-header">{props.header}</h2>
          </Container>
          <Carousel className="mb-4 pb-4" interval={30000}>
            {props.estates.map((_currentEstate, index) => {
              if (index === 0) {
                return (
                  <Carousel.Item key={v4()}>
                    <span className="flex-wrapper">
                      {props.estates.slice(0, 3).map((estate) => {
                        return (
                          <Estate
                            key={v4()}
                            estate={estate}
                            showDetails={showDetails}
                            isInSlide={true}
                          />
                        );
                      })}
                    </span>
                  </Carousel.Item>
                );
              } else if (index > 2 && index % cardNumber === 0) {
                const lastThreeEstates = props.estates.slice(
                  index,
                  index + cardNumber
                );
                return (
                  <Carousel.Item key={v4()} >
                    <span className="flex-wrapper">
                      {lastThreeEstates.map((estate) => {
                        return (
                          <Estate
                            key={v4()}
                            estate={estate}
                            showDetails={showDetails}
                            isInSlide={true}
                          />
                        );
                      })}
                    </span>
                  </Carousel.Item>
                );
              } else {
                return null;
              }
            })}
          </Carousel>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default EstateSlider;
