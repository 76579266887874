import AboutUs from "../components/sections/AboutUs";
import Layout from "../components/layout/Layout";
import React from "react";

const WhyUsPage = () => {
  return (
    <Layout page="warumwir">
      <AboutUs />
    </Layout>
  );
};

export default WhyUsPage;
