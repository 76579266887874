import React from "react";
import { Container } from "react-bootstrap";

const WhoAreWe = () => {
  return (
    <Container>
      <h2 className="gold-header">Wer sind wir?</h2>
      <p className="line-height-2 center-left">
        Dürfen wir uns vorstellen? Christiane und Philip Detharding! Wir
        ergänzen uns nicht nur privat perfekt, sondern auch als verlässlicher
        Partner rund um Ihre Immobilienanliegen: Während Christiane meist
        unterwegs ist und unsere Kunden und Objekte vor Ort betreut, behält
        Philip im Backoffice alles im Blick. Unterstützt werden wir von unseren 
        Kolleginnen und Freundinnen Nicole und Angelika. Das Wichtigste dabei ist für uns, dass wir immer
        persönlich für unsere Kunden da sind – von der ersten Anfrage bis zur
        Vertragsunterzeichnung und bei Bedarf gerne noch darüber hinaus. Nehmen
        Sie einfach Kontakt zu uns auf: Wir freuen uns, auch Ihnen neue Türen
        öffnen zu dürfen!
      </p>
    </Container>
  );
};

export default WhoAreWe;
