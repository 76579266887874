import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import SoldEstateListWithoutFilter from "../estates/SoldEstateListWithoutFilter";
import SoldEstate from "../estates/sliders/SoldEstate";
import FullService from "./partials/FullService";
import Partners from "./partials/Partners";
import Portfolio from "./partials/Portfolio";
import Testimonials from "./partials/Testimonials";
import WhatWeWant from "./partials/WhatWeWant";
import WhoAreWe from "./partials/WhoAreWe";
import WhyUs from "./partials/WhyUs";

const AboutUs = () => {
  return (
    <article className="text-center">
      <section className="bg-di-secondary">
        <StaticImage
          src={"../../assets/images/interviews/wer-sind-wir-detharding.jpg"}
          alt="Wer sind wir Detharding"
          placeholder="blurred"
          layout="fixed"
          className="w-100"
          objectPosition="top"
          style={{ height: "64.3vh" }}
          quality={100}
        />
      </section>
      <section className="bg-di-secondary whoarewe">
        <WhoAreWe />
      </section>
      <section className="whyus">
        <WhyUs />
      </section>
      <section className="testimonials dethardings-voice bg-light-gray">
        <WhatWeWant />
      </section>
      <section
        className="bg-di-primary fullservice"
        style={{ marginBottom: "-100px" }}
      >
        <FullService />
      </section>
      <section className="portfolio bg-di-primary">
        <Portfolio golden={true} />
      </section>
      <section className="">
        {/* display on Desktop */}
        <span className="d-none d-lg-block slide-estate" >
          <SoldEstate />
        </span>
        {/* display on Mobile */}
        <span className="d-lg-none">
          <SoldEstateListWithoutFilter />
        </span>
      </section>
      <section className="testimonials">
        <Testimonials />
      </section>
      <section className="certificate my-4">
        <h2 className="gold-header">Auszeichnungen</h2>
        <StaticImage
          src={"../../assets/images/pdf/CHIP_Auszeichnung.png"}
          alt="CHIP Auszeichnung"
          placeholder="blurred"
          height={300}
          imgClassName="certificate-grayscale"
        />
        <StaticImage
          src={"../../assets/images/pdf/Capital.jpg"}
          alt="CHIP Auszeichnung"
          placeholder="blurred"
          height={300}
        />
      </section>
      <section className="partners bg-light-gray">
        <Partners small={true} />
      </section>
    </article>
  );
};

export default AboutUs;
