import { Button, Carousel } from "react-bootstrap";

import { Link } from "gatsby";
import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const Testimonials = () => {
  const interviews = [
    {
      quote:
        "Für uns war das der erste Hausverkauf, da hat \nman natürlich jede Menge Fragen, die sie alle geduldig \nbeantwortet haben. Wir haben uns unheimlich gut unterstützt gefühlt.",
      source: "Caro & Daniel, Kassel",
    },
    {
      quote:
        "Wir hatten von Anfang an das gute Gefühl, dass die beiden nicht nur extrem kompetent sind, sondern sich auch mit Herzblut dafür einsetzen, dass wir das passende Haus finden.",
      source: "Manuel Klinge, Kassel",
    },
  ];

  return (
    <Carousel interval={30000}>
      <Carousel.Item>
        <Link to="/interview-daniel-caro" title="Zum Interview">
          <StaticImage
            className="d-block w-100"
            src={
              "../../../assets/images/interviews/interview-slide-caro-daniel.jpg"
            }
            alt="Interview mit Caro & Daniel"
            placeholder="blurred"
            layout="fullWidth"
            title="Zum Interview"
            quality={100}
          />
          <Carousel.Caption>
            <div className="caption-content">
              <hr className="gold-line" />
              <q>{interviews[0].quote}</q>
              <br />
              <b>{interviews[0].source}</b>
              <br />
              <Button>Zum Interview</Button>
            </div>
          </Carousel.Caption>
        </Link>
      </Carousel.Item>
      <Carousel.Item>
        <Link to="/interview-manuel-klinge" title="Zum Interview">
          <StaticImage
            className="d-block w-100"
            src={
              "../../../assets/images/interviews/interview-slide-daniel-klinge.jpg"
            }
            alt="Interview mit Manuel Klinge"
            placeholder="blurred"
            layout="fullWidth"
            quality={100}
          />
          <Carousel.Caption>
            <div className="caption-content">
              <hr className="gold-line" />
              <q>{interviews[1].quote}</q>
              <br />
              <b>{interviews[1].source}</b>
              <br />
              <Button>Zum Interview</Button>
            </div>
          </Carousel.Caption>
        </Link>
      </Carousel.Item>
    </Carousel>
  );
};

export default Testimonials;
