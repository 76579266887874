import { Container } from "react-bootstrap";
import React from "react";

const WhyUs = () => {
  return (
    <Container>
      <h2 className="gold-header">Warum wir?</h2>
      <p className="line-height-2 center-left">
        Als wir beschlossen haben, unser eigenes Maklerunternehmen zu gründen,
        hatten wir bereits jede Menge Berufserfahrung – aber vor allem auch
        unsere ganz eigenen Vorstellungen davon, was einen guten
        Immobilienmakler auszeichnet. Wir möchten für unsere Kunden nicht
        einfach nur Haustüren aufschließen, wir möchten sie ganz individuell auf
        ihrem spannenden und emotionalen Weg zum neuen Zuhause oder auch zum
        Verkauf ihrer Immobilie begleiten – und das mit einem umfassenden
        Service, der keine Wünsche offenlässt.
      </p>
    </Container>
  );
};

export default WhyUs;
