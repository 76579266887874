import Estate from "./Estate";
import React from "react";
import OnOfficeEstate from "../../types/estate";
import OnOfficeEstatesQuery from "../../queries/onOfficeEstates";
import {Container} from "react-bootstrap";

const SoldEstateListWithoutFilter = () => {

  const soldEstates: OnOfficeEstate[] = OnOfficeEstatesQuery().filter(
    (obj) => obj.ind_2038_Feld_ObjTech66 === "ind_Schl_3023" // referenz
  );

  return (
    <>
      <Container>
        <h2 className="gold-header">Referenzen</h2>
      </Container>
      <section className="mx-4 mb-4 flex-wrapper">
        {soldEstates.length === 0 ? (
          <p
            className="text-center"
          >
            Es wurden keine verfügbaren Objekte gefunden.
          </p>
        ) : (
          <>
            {soldEstates.map((estate, index) => {
              return <Estate key={index} estate={estate} showDetails={false} />;
            })}
          </>
        )}
      </section>
    </>
  );
};

export default SoldEstateListWithoutFilter;
